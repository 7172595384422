<script>
import first from 'lodash/first';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import {
  DATE_FORMAT,
  formatUtc,
  isNilString,
  LOG_TYPE,
  logger,
  sentenceCase,
} from '@emobg/web-utils';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { AlertsTemplate } from '@/templates';
import cases from '@/domains/CasesServices/router/CasesServicesRouterMap';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { PermissionLink } from '@/components';

import BookingTypeBadge from '../../components/BookingTypeBadge/BookingTypeBadge';
import fleet from '../../router/FleetRouterMap';
import { formatNil } from '../../utils/formatters';

export default {
  name: 'BookingSummaryPopup',
  components: {
    AlertsTemplate,
    BookingTypeBadge,
    PermissionLink,
  },
  props: {
    isOneWay: {
      type: Boolean,
      default: false,
    },
    bookingId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      booking: null,
      vehicleDamages: [],
      caseEvents: [],
    };
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    vehicleModelBrand() {
      return `${this.booking.vehicle_brand} ${this.booking.vehicle_model}`;
    },
  },
  created() {
    this.fleet = fleet;
    this.cases = cases;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.carsharingRoutes = carsharingRoutes;
    this.crm = crm;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  mounted() {
    this.getBooking(this.bookingId);
    this.getVehicleDamages(this.bookingId);
    this.getCaseEvents(this.bookingId);
  },
  methods: {
    get,
    isNil,
    isNilString,
    sentenceCase,
    formatUtc,
    size,
    formatNil,
    async getBooking(bookingId) {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.csBookings, {
          filters: `id:${bookingId}`,
        });
        this.booking = first(hits);
      } catch (error) {
        logger.message(`No hits for booking id: '${bookingId}'`);
      }
    },
    async getVehicleDamages(bookingId) {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.vehicleDamages, {
          filters: `cs_booking_fk:${bookingId}`,
        });
        this.vehicleDamages = hits;
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
      }
    },
    async getCaseEvents(id) {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.caseEvents, {
          filters: `booking_fk:${id}`,
        });
        this.caseEvents = hits;
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
      }
    },

  },
};
</script>
<template>
  <section
    data-test-id="booking_summary-popup"
    class="BookingSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white"
  >
    <div>
      <h2 class="emobg-font-weight-bold emobg-font-large">
        Booking
      </h2>
      <section class="mb-3 emobg-body-x-small">
        <div class="row pb-1 emobg-body-default mb-3">
          <div class="col-4 emobg-caption-2">
            <PermissionLink
              v-if="booking"
              :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
              :to="{
                name: carsharingRoutes.bookings.detail.index,
                params: {
                  bookingUuid: get(booking, 'uuid'),
                },
              }"
              data-test-id="carsharing_routes-link"
            >
              #{{ formatNil(booking.id) }}
            </PermissionLink>
            <ui-skeleton v-else />
          </div>
          <div class="col-8">
            <BookingTypeBadge
              v-if="booking"
              :type="booking.is_unavailability ? 'unavailability' : booking.booking_type"
              :is-automatic="booking.is_automatic"
              font-class="emobg-body-x-small"
              data-test-id="booking_type-badge"
            />
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Operator
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ booking.cs_operator }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div
          v-if="booking && !isNilString(booking.pre_booking_uuid)"
          class="row pb-1"
        >
          <div class="col-4 emobg-font-weight-bold">
            Pre-booking UUID
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatNil(booking.pre_booking_uuid) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Creation date
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatUtc(booking.created_at, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Start date
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatUtc(booking.start_utc, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div
          v-if="get(booking, 'real_start')"
          class="row pb-1 mt-n1 emobg-color-ink-light"
        >
          <div class="col-4">
            Real start date
          </div>
          <div
            class="col-8"
            data-test-id="real_start"
          >
            {{ formatUtc(booking.real_start, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            End date
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatUtc(booking.end_utc, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div
          v-if="get(booking, 'real_end')"
          class="row pb-1 mt-n1 emobg-color-ink-light"
        >
          <div class="col-4">
            Real end date
          </div>
          <div
            class="col-8"
            data-test-id="real_end"
          >
            {{ formatUtc(booking.real_end, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4 emobg-font-weight-bold">
            System status
          </div>
          <div class="col-8">
            <span
              v-if="booking"
              class="px-1 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill"
            >
              <i class="d-inline-block icons-system-status align-middle" />
              {{ formatNil(sentenceCase(booking.status)) }}
            </span>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4 emobg-font-weight-bold">
            Usage status
          </div>
          <div class="col-8">
            <span
              v-if="booking"
              class="px-1 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill"
            >
              <i class="d-inline-block icons-vehicle-status align-middle" />
              <template v-if="booking.is_unavailability">
                {{ FALLBACK_MESSAGE.notAvailable }}
              </template>
              <template v-else>
                {{ formatNil(sentenceCase(booking.usage_status)) }}
              </template>
            </span>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Cases
          </div>
          <div class="col-8">
            <p v-if="!size(caseEvents)">
              {{ FALLBACK_MESSAGE.dash }}
            </p>
            <PermissionLink
              v-for="caseEvent in caseEvents"
              :key="caseEvent.uuid"
              :link-permissions="[CRM_PERMISSIONS.viewCrmCases]"
              :to="{
                name: cases.caseEvents.detail,
                params: {
                  caseEventUuid: caseEvent.uuid
                }
              }"
              target="_blank"
              :data-test-id="`case_event_${caseEvent.uuid}-link`"
            >
              #{{ caseEvent.id }}
            </PermissionLink>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Damages
          </div>
          <div class="col-8">
            <p v-if="!size(vehicleDamages)">
              {{ FALLBACK_MESSAGE.dash }}
            </p>
            <PermissionLink
              v-for="vehicleDamage in vehicleDamages"
              :key="vehicleDamage.uuid"
              :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingDamages]"
              :href="`/fleet/vehicles/${vehicleDamage.vehicle.uuid}/vehicle-damages/${vehicleDamage.uuid}`"
              :data-test-id="`vehicle_damage_${vehicleDamage.uuid}-link`"
              target="_blank"
            >
              #{{ vehicleDamage.id }}
            </PermissionLink>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Alerts
          </div>
          <div class="col-8">
            <AlertsTemplate
              v-if="booking"
              :alerts="booking.alert_reason"
              data-test-id="alert_reason"
              class="pb-1"
            />
            <ui-skeleton v-else />
          </div>
        </div>
      </section>
    </div>
    <div>
      <h2 class="emobg-font-weight-bold emobg-font-large">
        Vehicle
      </h2>
      <section class="mb-3 emobg-body-x-small">
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            License plate
          </div>
          <div class="col-8">
            <PermissionLink
              v-if="booking && booking.vehicle_uuid"
              :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
              :to="{
                name: fleet.vehicles.detail.details,
                params: {
                  vehicleUuid: booking.vehicle_uuid
                }
              }"
              target="_blank"
            >
              {{ formatNil(booking.vehicle_plate) }}
            </PermissionLink>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Model
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ vehicleModelBrand }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Category
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatNil(booking.vehicle_category) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
      </section>
    </div>
    <div>
      <h2 class="emobg-font-weight-bold emobg-font-large">
        Location
      </h2>
      <section class="mb-3 emobg-body-x-small">
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            {{ isOneWay && booking && booking.destination_location ? 'Pickup location' : 'Location' }}
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatNil(booking.location) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div
          v-if="isOneWay"
          class="row pb-1"
        >
          <div class="col-4 emobg-font-weight-bold">
            Return location
          </div>
          <div class="col-8">
            <template v-if="booking">
              {{ formatNil(get(booking, 'destination_location.name')) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
      </section>
    </div>
    <div>
      <h2 class="emobg-font-weight-bold emobg-font-large">
        User
      </h2>
      <section class="mb-3 emobg-body-x-small">
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            User
          </div>
          <div class="col-8">
            <template v-if="booking">
              <p v-if="isNilString(booking.user_name) || isNil(booking.user_uuid)">
                {{ FALLBACK_MESSAGE.dash }}
              </p>
              <PermissionLink
                v-else
                :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
                :to="{
                  name: crm.users.detail.bookings,
                  params: {
                    userUuid: booking.user_uuid,
                  },
                }"
                target="_blank"
              >
                {{ formatNil(booking.user_name) }}
              </PermissionLink>
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4 emobg-font-weight-bold">
            Profile
          </div>
          <div class="col-8 text-capitalize">
            <template v-if="booking">
              {{ formatNil(booking.profile) }}
            </template>
            <ui-skeleton v-else />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
