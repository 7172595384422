<script>
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import fleet from '../router/FleetRouterMap';

export default {
  name: 'VehicleLink',
  props: {
    label: {
      type: String,
      default: '',
    },
    result: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    plateNumber() {
      return this.result.license_plate || this.result.vehicle_plate || FALLBACK_MESSAGE.dash;
    },
  },
  created() {
    this.fleet = fleet;
  },
};
</script>
<template>
  <ui-tooltip
    :tooltip="plateNumber"
    data-test-id="plate_number-tooltip"
    class="VehicleLink"
  >
    <div v-if="label">
      {{ label }} <br class="d-none d-md-block">
    </div>
    <RouterLink
      :to="{
        name: fleet.vehicles.detail.details,
        params: {
          vehicleUuid: result.vehicle_uuid || result.uuid
        }
      }"
      class="emobg-link-primary emobg-body-2"
      data-test-id="vehicle_details-link"
    >
      {{ plateNumber }}
    </RouterLink>
  </ui-tooltip>
</template>
<style lang="scss">
  .VehicleLink {
    .v1-MuiTooltip__content {
      display: block !important;
    }
  }
</style>
