import get from 'lodash/get';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { DAMAGES_STATUS_COLORS } from '@domains/Carsharing/Vehicles/VehicleDamages/damages.const';
import { INVOICING_PERMISSIONS } from '@/domains/Invoicing/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells() {
  const bookingTemplate = damage => {
    const to = (damage.cs_booking_fk) ? {
      name: carsharingRoutes.bookings.detail.index,
      params: {
        bookingUuid: damage.booking_uuid,
      },
    } : null;
    const text = damage.cs_booking_fk ? `#${damage.cs_booking_fk}` : FALLBACK_MESSAGE.dash;

    return {
      classLink: 'emobg-link-primary emobg-body-2',
      to,
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
      text,
    };
  };

  const invoiceTemplate = result => {
    const href = (result.invoice_fk ? `/invoicing/invoices/${result.invoice_fk}/summary/details` : null);
    const text = result.invoice_fk ? `${result.invoice_serie} ${result.invoice_number}` : FALLBACK_MESSAGE.dash;

    return {
      classLink: 'emobg-link-primary emobg-body-2',
      href,
      linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
      text,
    };
  };

  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      minWidth: 60,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: damage => ({
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: fleet.damages.edit,
          params: {
            vehicleUuid: damage.vehicle.uuid,
            damageUuid: damage.uuid,
          },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
        text: `#${damage.id}`,
      }),
    },
    {
      attributeName: 'vehicle.license_plate',
      title: 'Vehicle',
      displayPriority: 1,
      minWidth: 90,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: damage => ({
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: fleet.vehicles.detail.damages,
          params: {
            vehicleUuid: damage.vehicle.uuid,
          },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        text: damage.vehicle.license_plate,
      }),
    },
    {
      title: 'Model',
      transformResult: result => `${get(result, 'vehicle.brand_name')} - ${get(result, 'vehicle.model_name')}`,
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 150,
    },
    {
      title: 'Vehicle area',
      attributeName: 'vehicle_area',
      transformValue: sentenceCase,
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 150,
    },
    {
      title: 'Damage type',
      attributeName: 'damage_type',
      transformValue: sentenceCase,
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 150,
    },
    {
      attributeName: 'status',
      displayPriority: 1,
      title: 'Status',
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: DAMAGES_STATUS_COLORS[status],
        class: 'd-flex',
      }),
      columnRatio: 1,
      minWidth: 120,
    },
    {
      attributeName: 'reporting_date',
      title: 'Reporting date',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 120,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, this.operatorTimezone),
    },
    {
      attributeName: 'cs_booking_fk',
      title: 'Booking number',
      displayPriority: 0,
      columnRatio: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: bookingTemplate,
    },
    {
      attributeName: 'invoice_fk',
      title: 'Invoice number',
      displayPriority: 0,
      columnRatio: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: invoiceTemplate,
    },
    {
      attributeName: 'vehicle.cs_operator_name',
      title: 'Operator',
      displayPriority: 0,
      columnRatio: 1,
      minWidth: 120,
    },
    {
      attributeName: 'vehicle.city',
      title: 'City',
      displayPriority: 0,
      columnRatio: 1,
      minWidth: 120,
    },
  ];
}
