<script>
import first from 'lodash/first';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {
  FALLBACK_MESSAGE,
  formatKilometers,
  logger,
} from '@emobg/web-utils';

import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { ConnectivityStatusComponent, PermissionLink } from '@/components';
import fleet from '../../router/FleetRouterMap';
import BatteryLevel from '../../components/BatteryLevel/BatteryLevel';
import { FUEL_TYPES } from '../../../Fleet/VehicleModels/const/index.const';
import { formatNil } from '../../utils/formatters';

export default {
  name: 'VehicleSummaryPopup',
  components: {
    BatteryLevel,
    ConnectivityStatusComponent,
    PermissionLink,
  },
  props: {
    vehicleUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vehicle: undefined,
    };
  },
  computed: {
    isOneWayAllowed() {
      return get(this, 'vehicle.vehicle_category_one_way_allowed');
    },
    isElectricVehicle() {
      return get(this, 'vehicle.vehicle_fuel_type') === FUEL_TYPES.electric;
    },
    vehicleModelBrand() {
      return this.vehicle ? `${this.vehicle.vehicle_brand} ${this.vehicle.vehicle_model}` : FALLBACK_MESSAGE.dash;
    },
    plateNumber() {
      return (this.vehicle && (this.vehicle.license_plate || this.vehicle.vehicle_plate)) || FALLBACK_MESSAGE.dash;
    },
    vehicleFuelLevel() {
      return this.vehicle && this.vehicle.fuel_level ? `${this.vehicle.fuel_level} %` : FALLBACK_MESSAGE.dash;
    },
    tableEntries() {
      if (this.vehicle) {
        const vehicleInfo = {
          'Fuel level': this.vehicleFuelLevel,
          'Fuel type': formatNil(this.vehicle.vehicle_fuel_type),
          Battery: this.vehicle.battery_level ? `${this.vehicle.battery_level} V` : FALLBACK_MESSAGE.noData,
          Transmission: formatNil(this.vehicle.transmission),
          Mileage: formatKilometers(formatNil(this.vehicle.mileage)) || FALLBACK_MESSAGE.dash,
          'Number of seats': formatNil(this.vehicle.seats),
        };

        return this.isElectricVehicle ? omit(vehicleInfo, ['Fuel level']) : vehicleInfo;
      }
      return null;
    },
    connectivityStatus() {
      return get(this, 'vehicle.telemetry.connectivity_status');
    },
  },
  created() {
    this.fleet = fleet;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.getVehicle();
  },
  mounted() {
    this.getVehicle();
  },
  methods: {
    get,
    async getVehicle() {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.vehicles, {
          filters: `uuid:${this.vehicleUuid}`,
        });
        this.vehicle = first(hits);
      } catch (error) {
        logger.message(`No hits for vehicle uuid: '${this.vehicleUuid}'`);
      }
    },
  },
};
</script>
<template>
  <section
    class="VehicleSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white"
    data-test-id="vehicle_summary-popup"
  >
    <h2 class="emobg-font-large emobg-font-weight-bold">
      Vehicle
    </h2>
    <template v-if="vehicle">
      <img
        :src="vehicle.vehicle_model_picture"
        :alt="vehicleModelBrand"
        class="mx-1"
        width="200"
      >

      <section class="mb-3 mt-1 emobg-caption-1">
        <PermissionLink
          :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
          :to="{
            name: fleet.vehicles.detail.details,
            params: {
              vehicleUuid: vehicle.uuid || vehicle.vehicle_uuid
            }
          }"
          target="_blank"
          class="mt-1"
          data-test-id="link"
        >
          {{ plateNumber }}
        </PermissionLink>
        <p class="mt-1 d-flex align-items-center">
          <ui-icon
            v-if="isElectricVehicle"
            :icon="ICONS.electric"
            class="electric-vehicle-icon-color"
            data-test-id="electric_vehicle-icon"
          />
          {{ vehicleModelBrand }}
          <span class="text-capitalize ml-1"> ({{ vehicle.color }})</span>
        </p>
        <p class="my-2 d-flex align-items-center">
          <ui-icon
            v-if="isOneWayAllowed"
            :icon="ICONS.carPickUp"
            :size="ICONS_SIZES.xSmall"
            class="mr-1"
          />
          <span class="emobg-color-ink-light mr-2">{{ vehicle.vehicle_category }}</span>
          <BatteryLevel
            v-if="isElectricVehicle"
            :battery-level="get(vehicle, 'fuel_level')"
            :danger-level="get(vehicle, 'model.fuel_danger_level')"
            :icon-size="SIZES.large"
            text-class="emobg-font-x-small"
            data-test-id="battery_level"
          />
        </p>
        <p
          v-if="isOneWayAllowed"
          class="emobg-font-x-small emobg-color-ink-light"
        >
          Category available for one way
        </p>
      </section>
      <div class="row d-flex align-items-center pb-2 mb-1 emobg-font-x-small">
        <div class="col-6 emobg-font-weight-bold">
          Connectivity
        </div>
        <div class="col-6 d-flex align-items-center emobg-font-x-small">
          <ConnectivityStatusComponent
            :status="connectivityStatus"
            data-test-id="connectivity_status"
          />
        </div>
      </div>
      <div
        v-for="(value, key) of tableEntries"
        :key="key"
        class="row pb-2 mb-1 emobg-font-x-small"
      >
        <div class="col-6 emobg-font-weight-bold">
          {{ key }}
        </div>
        <div class="col-6 text-capitalize">
          {{ value }}
        </div>
      </div>
    </template>
    <div v-else>
      <ui-skeleton
        height="120"
        class="my-3"
      />
      <div class="row">
        <ui-skeleton
          height="16"
          rows="6"
          class="d-block col-6"
        />
        <ui-skeleton
          height="16"
          rows="6"
          class="d-block col-6"
        />
      </div>
    </div>
  </section>
</template>
