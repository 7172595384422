var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "BookingSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white",
      attrs: { "data-test-id": "booking_summary-popup" },
    },
    [
      _c("div", [
        _c("h2", { staticClass: "emobg-font-weight-bold emobg-font-large" }, [
          _vm._v(" Booking "),
        ]),
        _c("section", { staticClass: "mb-3 emobg-body-x-small" }, [
          _c("div", { staticClass: "row pb-1 emobg-body-default mb-3" }, [
            _c(
              "div",
              { staticClass: "col-4 emobg-caption-2" },
              [
                _vm.booking
                  ? _c(
                      "PermissionLink",
                      {
                        attrs: {
                          "link-permissions": [
                            _vm.CARSHARING_PERMISSIONS.viewCarsharingBookings,
                          ],
                          to: {
                            name: _vm.carsharingRoutes.bookings.detail.index,
                            params: {
                              bookingUuid: _vm.get(_vm.booking, "uuid"),
                            },
                          },
                          "data-test-id": "carsharing_routes-link",
                        },
                      },
                      [
                        _vm._v(
                          " #" + _vm._s(_vm.formatNil(_vm.booking.id)) + " "
                        ),
                      ]
                    )
                  : _c("ui-skeleton"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? _c("BookingTypeBadge", {
                      attrs: {
                        type: _vm.booking.is_unavailability
                          ? "unavailability"
                          : _vm.booking.booking_type,
                        "is-automatic": _vm.booking.is_automatic,
                        "font-class": "emobg-body-x-small",
                        "data-test-id": "booking_type-badge",
                      },
                    })
                  : _c("ui-skeleton"),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Operator "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [_vm._v(" " + _vm._s(_vm.booking.cs_operator) + " ")]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _vm.booking && !_vm.isNilString(_vm.booking.pre_booking_uuid)
            ? _c("div", { staticClass: "row pb-1" }, [
                _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
                  _vm._v(" Pre-booking UUID "),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _vm.booking
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatNil(_vm.booking.pre_booking_uuid)
                              ) +
                              " "
                          ),
                        ]
                      : _c("ui-skeleton"),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Creation date "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.booking.created_at,
                              _vm.DATE_FORMAT.defaultExtendedSeconds,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Start date "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.booking.start_utc,
                              _vm.DATE_FORMAT.defaultExtendedSeconds,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _vm.get(_vm.booking, "real_start")
            ? _c(
                "div",
                { staticClass: "row pb-1 mt-n1 emobg-color-ink-light" },
                [
                  _c("div", { staticClass: "col-4" }, [
                    _vm._v(" Real start date "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-8",
                      attrs: { "data-test-id": "real_start" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.booking.real_start,
                              _vm.DATE_FORMAT.defaultExtendedSeconds,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" End date "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.booking.end_utc,
                              _vm.DATE_FORMAT.defaultExtendedSeconds,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _vm.get(_vm.booking, "real_end")
            ? _c(
                "div",
                { staticClass: "row pb-1 mt-n1 emobg-color-ink-light" },
                [
                  _c("div", { staticClass: "col-4" }, [
                    _vm._v(" Real end date "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-8",
                      attrs: { "data-test-id": "real_end" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatUtc(
                              _vm.booking.real_end,
                              _vm.DATE_FORMAT.defaultExtendedSeconds,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row py-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" System status "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "px-1 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill",
                      },
                      [
                        _c("i", {
                          staticClass:
                            "d-inline-block icons-system-status align-middle",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatNil(
                                _vm.sentenceCase(_vm.booking.status)
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _c("ui-skeleton"),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row py-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Usage status "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "px-1 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill",
                      },
                      [
                        _c("i", {
                          staticClass:
                            "d-inline-block icons-vehicle-status align-middle",
                        }),
                        _vm.booking.is_unavailability
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.FALLBACK_MESSAGE.notAvailable) +
                                  " "
                              ),
                            ]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatNil(
                                      _vm.sentenceCase(_vm.booking.usage_status)
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                      ],
                      2
                    )
                  : _c("ui-skeleton"),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Cases "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                !_vm.size(_vm.caseEvents)
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                    ])
                  : _vm._e(),
                _vm._l(_vm.caseEvents, function (caseEvent) {
                  return _c(
                    "PermissionLink",
                    {
                      key: caseEvent.uuid,
                      attrs: {
                        "link-permissions": [_vm.CRM_PERMISSIONS.viewCrmCases],
                        to: {
                          name: _vm.cases.caseEvents.detail,
                          params: {
                            caseEventUuid: caseEvent.uuid,
                          },
                        },
                        target: "_blank",
                        "data-test-id": `case_event_${caseEvent.uuid}-link`,
                      },
                    },
                    [_vm._v(" #" + _vm._s(caseEvent.id) + " ")]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Damages "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                !_vm.size(_vm.vehicleDamages)
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                    ])
                  : _vm._e(),
                _vm._l(_vm.vehicleDamages, function (vehicleDamage) {
                  return _c(
                    "PermissionLink",
                    {
                      key: vehicleDamage.uuid,
                      attrs: {
                        "link-permissions": [
                          _vm.CARSHARING_PERMISSIONS.viewCarsharingDamages,
                        ],
                        href: `/fleet/vehicles/${vehicleDamage.vehicle.uuid}/vehicle-damages/${vehicleDamage.uuid}`,
                        "data-test-id": `vehicle_damage_${vehicleDamage.uuid}-link`,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" #" + _vm._s(vehicleDamage.id) + " ")]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Alerts "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? _c("AlertsTemplate", {
                      staticClass: "pb-1",
                      attrs: {
                        alerts: _vm.booking.alert_reason,
                        "data-test-id": "alert_reason",
                      },
                    })
                  : _c("ui-skeleton"),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", [
        _c("h2", { staticClass: "emobg-font-weight-bold emobg-font-large" }, [
          _vm._v(" Vehicle "),
        ]),
        _c("section", { staticClass: "mb-3 emobg-body-x-small" }, [
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" License plate "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking && _vm.booking.vehicle_uuid
                  ? _c(
                      "PermissionLink",
                      {
                        attrs: {
                          "link-permissions": [
                            _vm.CARSHARING_PERMISSIONS.viewCarsharingVehicles,
                          ],
                          to: {
                            name: _vm.fleet.vehicles.detail.details,
                            params: {
                              vehicle_uuid: _vm.booking.vehicle_uuid,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatNil(_vm.booking.vehicle_plate)) +
                            " "
                        ),
                      ]
                    )
                  : _c("ui-skeleton"),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Model "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [_vm._v(" " + _vm._s(_vm.vehicleModelBrand) + " ")]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Category "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatNil(_vm.booking.vehicle_category)) +
                          " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c("div", [
        _c("h2", { staticClass: "emobg-font-weight-bold emobg-font-large" }, [
          _vm._v(" Location "),
        ]),
        _c("section", { staticClass: "mb-3 emobg-body-x-small" }, [
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isOneWay &&
                      _vm.booking &&
                      _vm.booking.destination_location
                      ? "Pickup location"
                      : "Location"
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " + _vm._s(_vm.formatNil(_vm.booking.location)) + " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _vm.isOneWay
            ? _c("div", { staticClass: "row pb-1" }, [
                _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
                  _vm._v(" Return location "),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _vm.booking
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatNil(
                                  _vm.get(
                                    _vm.booking,
                                    "destination_location.name"
                                  )
                                )
                              ) +
                              " "
                          ),
                        ]
                      : _c("ui-skeleton"),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", [
        _c("h2", { staticClass: "emobg-font-weight-bold emobg-font-large" }, [
          _vm._v(" User "),
        ]),
        _c("section", { staticClass: "mb-3 emobg-body-x-small" }, [
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" User "),
            ]),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _vm.booking
                  ? [
                      _vm.isNilString(_vm.booking.user_name) ||
                      _vm.isNil(_vm.booking.user_uuid)
                        ? _c("p", [
                            _vm._v(
                              " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                            ),
                          ])
                        : _c(
                            "PermissionLink",
                            {
                              attrs: {
                                "link-permissions": [
                                  _vm.CRM_PERMISSIONS.viewCrmUsers,
                                ],
                                to: {
                                  name: _vm.crm.users.detail.bookings,
                                  params: {
                                    userUuid: _vm.booking.user_uuid,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatNil(_vm.booking.user_name)) +
                                  " "
                              ),
                            ]
                          ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "row pb-1" }, [
            _c("div", { staticClass: "col-4 emobg-font-weight-bold" }, [
              _vm._v(" Profile "),
            ]),
            _c(
              "div",
              { staticClass: "col-8 text-capitalize" },
              [
                _vm.booking
                  ? [
                      _vm._v(
                        " " + _vm._s(_vm.formatNil(_vm.booking.profile)) + " "
                      ),
                    ]
                  : _c("ui-skeleton"),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }