var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-tooltip",
    {
      staticClass: "VehicleLink",
      attrs: {
        tooltip: _vm.plateNumber,
        "data-test-id": "plate_number-tooltip",
      },
    },
    [
      _vm.label
        ? _c("div", [
            _vm._v(" " + _vm._s(_vm.label) + " "),
            _c("br", { staticClass: "d-none d-md-block" }),
          ])
        : _vm._e(),
      _c(
        "RouterLink",
        {
          staticClass: "emobg-link-primary emobg-body-2",
          attrs: {
            to: {
              name: _vm.fleet.vehicles.detail.details,
              params: {
                vehicleUuid: _vm.result.vehicle_uuid || _vm.result.uuid,
              },
            },
            "data-test-id": "vehicle_details-link",
          },
        },
        [_vm._v(" " + _vm._s(_vm.plateNumber) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }